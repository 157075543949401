import React, { useState, useEffect } from "react"
import Axios from "axios"
import { getAllUrlParams } from "../../utils"
import MailChimpForm from "./form"

export default function Section2() {
  return (
    <section className="video-landing__form">
      <div className="container  px-0 sm:px-2 lg:px-0 flex flex-col md:flex-row  justify-between items-center py-0 mx-auto">
        <div className="left md:w-1/2 text-center md:text-left mx-auto ">
          <p className="desc">
            As some of you may be aware, e-invoicing is here to stay and if you
            were not aware, the Prime Minister delivered his Digital Business Plan
            to drive Australia's economic recovery (Sep 20) to include:
            <br />
            $3.6 million towards mandating the adoption of electronic invoicing by 1 July 2022
            for all Commonwealth government agencies to encourage greater adoption amongst businesses
            supplying to government and within their supply chains, and to consult on options for
            mandatory adoption of e-invoicing by businesses;
            <br />
            Here at LUCA Plus we're here to help you get ready for e-invoicing.
            <br />
            Find out what the ATO says about e-invoicing in Australia, learn how to
            avoid online scams and unnecessary costs associated with invoicing – and learn
            about e-invoicing in action through our case study.
            <br />
            Become e-invoicing ready, sooner, simply fill out the form and download your Free e-invoicing eBook today.
            <br />
            We're looking forward to taking you on your e-invoicing journey with LUCA Plus.

          </p>
        </div>

        <div className="right   md:w-1/2 mx-auto mt-0 sm:mt-16 md:mt-0">
          <MailChimpForm />
        </div>
      </div>
    </section>
  )
}
