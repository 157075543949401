import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function Request() {
  return (
    <section className="video-landing__request">
      <div className="container  flex  items-center h-full">
        <div className="  flex flex-col lg:flex-row justify-between items-center mx-auto w-4/5 ">
          <div className="left text-white text-center lg:text-left">
            <h2 className=" d-title mt-0 pt-0">
              E-invoicing is here to stay!
            </h2>
            <p className="d-desc">
              Learn everything you need to know to become e-invoicing ready.
              Download your FREE e-invoicing eBook to find out how.
            </p>
          </div>
          <a name="cta_button" id="cta_button"></a>
          <div className="right mt-8  lg:mt-0">
            <AnchorLink
              className="bg-mind  text-white 
            leading-tight focus:outline-none  
            request-demo-btn2"
              to="#download_form"
              title="Download Now"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
