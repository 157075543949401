import React from "react"

import meeting1 from "../../images/video/meeting1.png"
import meeting2 from "../../images/video/meeting2.png"
import playImg from "../../images/video/play.svg"
import Modali, { useModali } from "../modal"
import { scrollTo } from "../../utils"

export default function Meeting() {
  const [mod1, toggleMod1] = useModali({
    onHide: () => scrollTo("cta_button"),
  })
  const [mod2, toggleMod2] = useModali({
    onHide: () => scrollTo("cta_button"),
  })
  return (
    <section className="video-landing__meeting">
      <div className="container py-8  md:py-20 lg:pt-16 lg:pb-32">
        <h2 className="title">Let's get ready for e-invoicing together</h2>
        <p className="desc">
          Missed the recent LUCA Plus webinar with the ATO? <br /> <div className="block sm:hidden"><br /></div> See what the
          ATO’s, Mark Stockwell and LUCA Plus’, Ray Wang had to say about the
          future of e-invoicing in Australia.
        </p>
        <div className="mx-auto preview">
          Watch a preview of their presentations here
        </div>
        <div className="cards flex flex-col md:flex-row justify-around text-center">
          <div className="card">
            <img src={meeting1} alt="" className="rounded-full mx-auto w-1/2" />
            <div className="mt-8  font-medium">Mark Stockwell</div>
            <p className="pos font-medium">
              Head of Australian PEPPOL Authority and <br /> e-invoicing at the
              Australian Taxation Office
            </p>
            <p className="info">
              Leading implementation of Trans-Tasman e-invoicing initiative
              including necessary infrastructure (DCL). Collaborating with New
              Zealand and the Business communities. Chair of the Trans-Tasman
              e-invoicing Working Group.
            </p>
            <div className="mt-8 sm:mt-20 sm:pb-16 ">
              <a
                href="#"
                className="play-btn text-white focus:outline-none play-btn-1 "
                onClick={toggleMod1}
              >
                Watch Now <img src={playImg} className="inline-block ml-3" />
              </a>
            </div>
          </div>
          <div className="card pt-24 md:pt-0 ">
            <img src={meeting2} alt="" className="rounded-full mx-auto w-1/2" />
            <div className="mt-8 font-medium">Ray Wang</div>
            <p className="pos font-medium">
              CEO & Founder <br /> at LUCA Plus
            </p>
            <p className="  info">
              As a professional accountant, Ray Wang is Co-Founder/CEO of Luca
              Plus. Ray has experience in both commercial and public accounting
              industries including in Top 500 companies, other businesses and
              taxation accounting firms. Ray was part of a focus group on
              Fintech Digital transformation in Australia run by CPA (a CPA
              member himself).
            </p>
            <div className="mt-8 sm:pb-16">
              <a
                href="#"
                onClick={toggleMod2}
                className="play-btn text-white focus:outline-none "
              >
                Watch Now <img src={playImg} className="inline-block ml-3" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modali.Modal {...mod1}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/iBxwD5pqr8E?autoplay=1&enable_js=1"
            title="Introduction to LUCA Plus"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>

      <Modali.Modal {...mod2}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/Zf5E7TAhdvQ?autoplay=1&enable_js=1"
            title="Introduction to LUCA Plus"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
    </section>
  )
}
