import React from "react"
import ebookImg from "../../images/video/ebook.png"
import arrowImg from "../../images/partner/arrow.svg"
import { ebookUrl } from "../../constants/url"
import { AnchorLink } from "gatsby-plugin-anchor-links"
export default function Hero() {
  return (
    <div className="video-landing__hero flex sm:items-center">
      <div
        className="container  -mt-0 sm:-mt-20 md:-mt-0
      flex  flex-col  sm:flex-row justify-center items-center"
      >
        <div className="left">
          <h1 className="video-landing__hero-title mt-16 sm:mt-0">
            Let's Get E-invoicing Ready Together
          </h1>
          <p className="video-landing__hero-desc">
            Download our Free eBook and learn everything you
            need to know to become e-invoicing ready.
          </p>

          <div
            className=" mt-6 relative mx-auto sm:mx-0"
            style={{ width: 171 }}
          >
            <AnchorLink
              to="#download_form"
              title="Download Now"
              className="bg-mind capitalize rounded download-but
         sm:pr-12 focus:outline-none leading-5 
          mx-auto sm:ml-0"
            />
            <img
              src={arrowImg}
              className="inline-block mr-4 ml-2 absolute right-0 top-0 h-full"
            />
          </div>
        </div>
        <div className="right  mt-0 sm:mt-10 xl:mt-20">
          <img src={ebookImg} alt="ebbok" className="my-8 sm:my-0 right-img" />
        </div>
      </div>
    </div>
  )
}
