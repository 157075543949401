import React from "react"
import logo1 from "../../images/video/logo1.svg"
import logo2 from "../../images/video/logo2.svg"
import logo3 from "../../images/video/logo3.svg"
import logo4 from "../../images/video/logo4.svg"

// import { meetingUrl } from "../../constants/url"

export default function Partners({page}) {
  return (
    <section className={`video-landing__partners ${page !=="Partner Page" ? "hidden sm:block" : ""}`}>
      <div
        className="container lg:px-8
       sm:flex sm:items-center sm:justify-between sm:pt-8 inner"
      >
        <div className="left mr-8 lb:mr-0">
          <h2 className="accredited font-medium">Accredited and trusted by</h2>
        </div>
        <div className="right ">
          <div className="logos">
            <img src={logo1} className="logo xero-logo" />
            <img src={logo2} className="logo myob-logo ml-4 lg:ml-8" />
            <img src={logo3} className="logo qbo-logo ml-4 lg:ml-8" />
            <img src={logo4} className="logo peppol-logo ml-4 lg:ml-8" />
          </div>
        </div>
      </div>
    </section>
  )
}
