import React from "react"
import logoImage from "../../images/new-landing/logo.svg"
import { meetingUrl } from "../../constants/url"

export default function Top({navItemClick}) {
  return (
    <div className="top">
      <div className="z-20 container sm:px-10">
        <div className="top-header bg-white  flex justify-between items-center">
          <a href="/" className="logo ml-0 sm:mx-0">
            <img src={logoImage} alt="logo" />
          </a>
          <div className="right">
            <a href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus" className="hover:text-gray-26" onClick={() => navItemClick("Contact")}>
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
