import React, { useState } from "react"
import { useFormik } from "formik"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useLocation } from "@reach/router"
import Axios from "axios"
import Modali, { useModali } from "../modal"
import emailImg from "../../images/video/email.png"
import { navigate } from "gatsby"
import { ebookUrl } from "../../constants/url"

export default function MailChimpForm() {
  const [finishModal, toggleFinishModal] = useModali({
    large: false,
    onHide: () => navigate("/"),
  })
  console.log("finishModal ", finishModal)
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const location = useLocation()

  let mail_chimp_endpoint = ""
  if (process.env.GATSBY_APP_URL) {
    mail_chimp_endpoint =
      `${process.env.GATSBY_APP_URL}` + "/api/v1/forms/dynamic"
  } else {
    mail_chimp_endpoint =
      "https://luca-staging.lucaplus.com/api/v1/forms/dynamic"
    if (location.hostname === "lucaplus.com") {
      mail_chimp_endpoint = "https://app.lucaplus.com/api/v1/forms/dynamic"
    }
  }
  const validate = values => {
    const errors = {}
    setErrorMsg("");
    if (!values.firstName) {
      errors.firstName = "Required"
    }

    if (!values.lastName) {
      errors.lastName = "Required"
    }
    if (!values.company) {
      errors.company = "Required"
    }

    if (!values.phone) {
      errors.phone = "Required"
    } else if (values.phone.length < 10) {
      setErrorMsg("Phone should be 10 digit");
    }
    // if (!values.otherSoftware) {
    //   errors.otherSoftware = "Required"
    // }

    if (!values.customers) {
      errors.customers = "Required"
    }

    if (!values.challenges) {
      errors.challenges = "Required"
    }

    if (!values.software && !values.otherSoftware) {
      errors.software = "Required"
    }

    if (!values.email) {
      errors.email = "Required"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      checkbox1: true,
      checkbox2: true,
    },
    validate,
    onSubmit: async values => {
      // console.log(JSON.stringify(values, null, 2))
      setSubmitting(true)
      Axios.post(mail_chimp_endpoint, {
        email: values.email,
        audienceId: "08d7344fd8",
        tags: ["E-book_2020"],
        mergeFields: {
          FNAME: values.firstName,
          LNAME: values.lastName,
          EMAIL: values.email,
          PHONE: values.phone,
          MMERGE3: values.customers,
          MMERGE7: values.challenges,
          MMERGE5: values.software,
          MMERGE6: values.otherSoftware,
          COMPNAME: values.company,
          REQDEMO: values.checkbox1 ? "yes" : "no",
          ACCEPTTERM: values.checkbox2 ? "yes" : "no",
        },
      })
        .then(function(response) {
          console.log(response)
          setSubmitting(false)
          if (response) {
            if (response.data.success) {
              toggleFinishModal()
              setErrorMsg("")
              // setSubmitting(true)
              // alert("Thank you for downloading!")
              // window.location.href = `${process.env.GATSBY_APP_URL}` + "/login"
              window.open(ebookUrl);
            } else {
              console.log(response.data.message)
              setErrorMsg(response.data.message.split(". Use")[0])
            }
          }
          // window.location.href = `${process.env.GATSBY_APP_URL}` + "/login"
        })
        .catch(function(error) {
          console.log(error)
          setSubmitting(true)
          // window.location.href = `${process.env.GATSBY_APP_URL}` + "/login"
        })
    },
  })

  return (
    <div>
      <a name="download_form" id="download_form"></a>
      {errorMsg && (
        <div className=" text-red-600 mb-4 text-left">{errorMsg}</div>
      )}
      <form action="" className="" onSubmit={formik.handleSubmit}>
        <div className="">
          <input
            className={`small px-5 py-1 lgdark  border placeholder-gray-28
            ${
              formik.errors.firstName ? "border-red-600" : "border-gray-27"
            }  rounded`}
            name="firstName"
            type="text"
            required=""
            placeholder="First name"
            aria-label="First name"
            onChange={formik.handleChange}
            value={formik.values.firstName || ""}
          />

          <input
            className={`small ml-2 lg:ml-4 px-5 py-1 lgdark border placeholder-gray-28
             ${
               formik.errors.lastName ? "border-red-600" : "border-gray-27"
             }   rounded`}
            name="lastName"
            type="text"
            required=""
            placeholder="Last name"
            aria-label="Last name"
            onChange={formik.handleChange}
            value={formik.values.lastName || ""}
          />
        </div>
        <div className="mt-3">
          <input
            className={`small px-5 py-1 lgdark placeholder-gray-28  border   ${
              formik.errors.email ? "border-red-600" : "border-gray-27"
            }   rounded`}
            name="email"
            type="text"
            required=""
            placeholder="Email "
            aria-label="Email"
            onChange={formik.handleChange}
            value={formik.values.email || ""}
          />
          <input
            className={`small ml-2 lg:ml-4 px-5 py-1 lgdark placeholder-gray-28  border   ${
              formik.errors.phone ? "border-red-600" : "border-gray-27"
            }  rounded`}
            name="phone"
            type="text"
            required=""
            maxLength={10}
            placeholder="Phone"
            aria-label="Phone"
            onChange={formik.handleChange}
            value={formik.values.phone || ""}
          />
        </div>
        <div className="mt-3">
          <input
            className={`big px-5 py-1 lgdark placeholder-gray-28   border ${
              formik.errors.company ? "border-red-600" : "border-gray-27"
            }  rounded`}
            name="company"
            type="text"
            required=""
            placeholder="Business Name"
            aria-label="Company"
            onChange={formik.handleChange}
            value={formik.values.company || ""}
          />
        </div>
        <div className="mt-3">
          <label className="block text-left lable" htmlFor="numbers">
            Number of customers/suppliers you work with
          </label>
          <select
            name="customers"
            value={formik.values.customers}
            onChange={formik.handleChange}
            className={`big big-select px-5 py-1 lgdark placeholder-gray-28  border ${
              formik.errors.customers ? "border-red-600" : "border-gray-27"
            }  rounded`}
          >
            <option value=""> Select</option>
            <option value="1+">1+</option>
            <option value="5+">5+</option>
            <option value="10+">10+</option>
            <option value="20+">20+</option>
            <option value="50+">50+</option>
          </select>
        </div>

        <div className="mt-3">
          <label className="block text-left lable  " htmlFor="numbers">
            What are your business challenges?
          </label>
          <select
            name="challenges"
            value={formik.values.challenges}
            onChange={formik.handleChange}
            className={`big big-select px-5 py-1
          lgdark placeholder-gray-28   border ${
            formik.errors.challenges ? "border-red-600" : "border-gray-27"
          }   rounded`}
          >
            <option value="">Select</option>
            <option value="Chasing payments">Chasing payments</option>
            <option value="Invoice reconciliation">
              Invoice reconciliation
            </option>
            <option value="Sourcing the right e-invoicing solution">
              Sourcing the right e-invoicing solution
            </option>
            <option value="Unsure if my existing accounting software provider can e-invoice">
              Unsure if my existing accounting software provider can e-invoice
            </option>
            <option value="I need to streamline , but I don't have the time">
              I need to streamline , but I don't have the time
            </option>
          </select>
        </div>
        <div className="mt-3">
          <label className="block text-left lable" htmlFor="numbers">
            What accounting software are you using?
          </label>
          <select
            className={`big big-select py-0 lgdark
                border ${
                  formik.errors.software ? "border-red-600" : "border-gray-27"
                }  rounded`}
            name="software"
            value={formik.values.software}
            onChange={formik.handleChange}>
            <optgroup>
              <option value="">Select</option>
              <option value="XERO">XERO</option>
              <option value="MYOB">MYOB</option>
              <option value="QuickBooks">QuickBooks</option>
              <option value="SAGE">SAGE</option>
              <option value="APS">APS</option>
            </optgroup>
          </select>
        </div>
        <div className="mt-3">
          <label className="block text-left lable" htmlFor="numbers">
            Other accounting software
          </label>
          <input
            className={`big px-5 py-1 lgdark  border border-gray-27  roundedr ${
              formik.errors.otherSoftware ? "border-gray-27" : "border-gray-27"
            } rounded `}
            name="otherSoftware"
            type="text"
            required=""
            placeholder=""
            aria-label="Other accounting software"
            onChange={formik.handleChange}
            value={formik.values.otherSoftware || ""}
          />
        </div>
        <div className="w-full flex items-center justify-start mt-6 mb-3 ">
          <input
            type="checkbox"
            name="checkbox1"
            checked={formik.values.checkbox1 || false}
            onChange={formik.handleChange}
            className="bg-white border border-gray-27 "
          />
          <span className="text-sm text-gray-700   text-left ml-2">
            Request a demo of LUCA Plus
          </span>
        </div>
        <div
          className="w-full flex items-start sm:items-center justify-start
        "
        >
          <input
            type="checkbox"
            name="checkbox2"
            checked={formik.values.checkbox2 || false}
            onChange={formik.handleChange}
            className="bg-white  border border-gray-27"
          />
          <span className="text-sm text-left text-gray-700 ml-2 ">
            I accept LUCA Plus Terms of Services and Privacy Policy
          </span>
        </div>
        <button
          type="submit"
          className={`w-full text-white  focus:outline-none  submit ${
            submitting ? "bg-gray-6" : "bg-mind"
          } mt-6`}
          disabled={submitting}
        >
          Download Now
        </button>
      </form>
      <Modali.Modal {...finishModal} className=" for-video">
        <div className="pt-8 pop-up-video mx-auto ">
          <div className="pop-up-video-inner text-gray-8 mx-auto ">
            <img src={emailImg} alt="" className="inbox-image mx-auto" />
            <div className="leading-relaxed mt-8">
              Thank you for downloading our eBook. <br />
              Please check your inbox for your backup link.
            </div>
            <div className="mt-8 text-gray-8  text-lg">Team Luca Plus</div>
          </div>
        </div>
      </Modali.Modal>
    </div>
  )
}
