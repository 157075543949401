import React from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"
import Footer from "../components/footer"
import Top from "../components/video/top"
import Hero from "../components/video/hero"
import Section2 from "../components/video/section2"
import Request from "../components/video/request"
import Partners from "../components/video/partners"
import Meeting from "../components/video/meeting"
import seoImage from "../images/video/ebook.png"

import "../scss/style.scss"
export default function NewLandingPage() {
    const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of Ebook Page`
        });
    }
  return (
    <div className="h-full font-robo video-landing">
      <SEO title="E-invoicing eBook (FREE Download from LUCA+)" description="Learn everything you need to know about e-invoicing. Download 'What is e-invoicing?' ebook for FREE." image={seoImage} 
      keywords="E-invoicing Info" />
      <Top navItemClick={navItemClick} />
      <Hero />
      <Partners />
      <Section2 />
      <Meeting />
      <Request />
      <Footer simple={true} />
    </div>
  )
}
